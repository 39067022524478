const prefix = 'connect/';

export const GET_POSTS = `${prefix}GET_POSTS`;
export const GET_POSTS_SUCCESS = `${prefix}GET_POSTS_SUCCESS`;

export const APPROVE_POST = `${prefix}APPROVE_POST`;
export const APPROVE_POST_SUCCESS = `${prefix}APPROVE_POST_SUCCESS`;

export const TOGGLE_HIDE_POST = `${prefix}TOGGLE_HIDE_POST`;
export const TOGGLE_HIDE_POST_SUCCESS = `${prefix}TOGGLE_HIDE_POST_SUCCESS`;

export const GET_GROUPS = `${prefix}GET_GROUPS`;
export const GET_GROUPS_SUCCESS = `${prefix}GET_GROUPS_SUCCESS`;

export const SET_IS_LOADING = `${prefix}SET_IS_LOADING`;
