import api from './Api';

const geUsersLovers = ({ skip, limit, communityId, searchTerm, onlyPending }) => {
  const query = api.getQueryParamsFromObject({ skip, limit, search_term: searchTerm, onlyPending });
  return api.get(`/v2/admin/community/${communityId}/community-lovers?${query}`);
};

const addUserLover = (communityId, userId) => {
  return api.post(`/v2/admin/community/${communityId}/community-lovers/${userId}/promote`);
};

const removeUserLover = (communityId, userId) => {
  return api.delete(`/v2/admin/community/${communityId}/community-lovers/${userId}/demote`);
};

const updateCommunityLover = (communityId, data) => {
  return api.patch(`/v2/admin/community/${communityId}`, data);
};

export default {
  geUsersLovers,
  addUserLover,
  removeUserLover,
  updateCommunityLover,
};
