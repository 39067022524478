import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import qs from 'query-string';
import { connect } from 'react-redux';
import { useTranslate } from 'react-admin';
import { useLocation } from 'react-router-dom';
import * as shareittLoversActions from '../../store/shareittLovers/actions';
import * as shareittLoversSelectors from '../../store/shareittLovers/selectors';
import PageContent from '../../components/PageContent';
import TabsPanel from '../../components/TabsAccordion/TabsPanel';
import TabsContent from '../../components/TabsAccordion/TabsContent';
import ShareittLoversTable from './ShareittLoversTable';
import CriteriaPage from './CriteriaPage';
import EmblemPage from './EmblemPage';
import AddUserModal from './components/AddUserModal';
import Popup from '../../components/Popup';
import Plus from '../../assets/icons/plus.svg';

const ShareittLovers = ({ usersLovers, addUserLover, getUsersLovers, removeUserLover }) => {
  const translate = useTranslate();
  const location = useLocation();
  const queryParams = qs.parse(location.search);
  const [filterType, setFilterType] = useState('user');
  const [isShowAddUserModal, setIsShowAddUserModal] = useState(false);
  const [isShowRemoveUserModal, setIsShowRemoveUserModal] = useState({
    visible: false,
    userId: null,
  });

  const makeGetUsersLoversFunc = () => {
    return getUsersLovers;
  };

  const getUsersShareittLovers = makeGetUsersLoversFunc();

  useEffect(() => {
    getUsersShareittLovers();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tabOptions = [
    {
      value: 'user',
      label: translate('pages.shareitt_lovers.tabs.user_lovers'),
    },
    {
      value: 'criteria',
      label: translate('pages.shareitt_lovers.tabs.criteria'),
    },
    {
      value: 'emblem',
      label: translate('pages.shareitt_lovers.tabs.emblem'),
    },
  ];

  const onTabClick = (value) => {
    setFilterType(value);
  };

  const handleAddUseModal = () => {
    setIsShowAddUserModal((prev) => !prev);
  };

  const handleAddUser = (user) => {
    addUserLover(user.id);
  };

  const handleShowPopupRemoveUser = (userId) => {
    setIsShowRemoveUserModal({ visible: true, userId });
  };

  const renderTabContent = () => {
    switch (filterType) {
      case 'user':
        return (
          <>
            <div className="bg-white w-full flex items-center justify-end px-9 py-5">
              <button
                className="text-lg font-medium text-bluePrimary flex flex-row items-center"
                onClick={handleAddUseModal}
              >
                <img src={Plus} alt="plus" className="mr-1" />
                {translate('pages.shareitt_lovers.add')}
              </button>
            </div>
            <ShareittLoversTable
              posts={usersLovers}
              getPosts={getUsersShareittLovers}
              type={filterType}
              searchTerm={queryParams.searchTerm}
              onRemoveUser={handleShowPopupRemoveUser}
            />

            {isShowAddUserModal && (
              <AddUserModal onAddMember={handleAddUser} closeModal={handleAddUseModal} />
            )}

            {isShowRemoveUserModal.visible && (
              <Popup
                title={translate('pages.shareitt_lovers.remove_user_title')}
                onConfirm={() => {
                  removeUserLover(isShowRemoveUserModal.userId);
                  setIsShowRemoveUserModal({ visible: false, userId: null });
                }}
                closeModal={() => setIsShowRemoveUserModal({ visible: false, userId: null })}
              />
            )}
          </>
        );
      case 'criteria':
        return <CriteriaPage />;
      case 'emblem':
        return <EmblemPage />;
      default:
        return <CriteriaPage />;
    }
  };

  return (
    <PageContent title={translate('pages.shareitt_lovers.title.page')}>
      <TabsPanel options={tabOptions} activeTab={filterType} onTabClick={onTabClick} />

      <TabsContent>{renderTabContent()}</TabsContent>
    </PageContent>
  );
};

ShareittLovers.propTypes = {
  posts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isLoading: PropTypes.bool.isRequired,
  getPosts: PropTypes.func.isRequired,
  getSuspensions: PropTypes.func.isRequired,
  getSuspicions: PropTypes.func.isRequired,
};

export default connect(
  (state) => {
    return {
      usersLovers: shareittLoversSelectors.getUsersLovers(state),
    };
  },
  {
    getUsersLovers: shareittLoversActions.getUsersLovers,
    addUserLover: shareittLoversActions.addUserLover,
    removeUserLover: shareittLoversActions.removeUserLover,
  },
)(ShareittLovers);
