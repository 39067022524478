import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import qs from 'query-string';
import { connect } from 'react-redux';
import { useTranslate } from 'react-admin';
import { useLocation } from 'react-router-dom';
import * as ConnectActions from '../../store/connect/actions';
import * as ConnectSelectors from '../../store/connect/selectors';
import PageContent from '../../components/PageContent';
import ConnectTable from './ConnectTable';
import SearchInput from '../../components/Search';
import Select from '../../components/Select';
import PostItemDetails from './components/PostItemDetails';

const Connect = ({ posts, groups, isLoading, getPosts, getGroups, toggleHidePost }) => {
  const translate = useTranslate();
  const location = useLocation();
  const queryParams = qs.parse(location.search);
  const filterType = 'Pending';
  const [searchTerm, setSearchTerm] = useState(queryParams.searchTerm || '');
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [postDetailsState, setPostDetailsState] = useState({
    visible: false,
    post: null,
  });

  const makeGetPostsFunc = () => {
    return getPosts;
  };

  const getConnectPosts = makeGetPostsFunc();

  useEffect(() => {
    if (queryParams.type) {
      // setMemberFilterType(queryParams.type);
    } else {
      getConnectPosts();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearchChange = (term) => {
    setSearchTerm(term);
  };

  useEffect(() => {
    getPosts({ searchTerm, sub_communities_ids: selectedGroups.join(',') });
  }, [getPosts, searchTerm]);

  useEffect(() => {
    getGroups();
  }, []);

  const onChangeGroup = (groups) => {
    const groupIds = groups.map((group) => group.id);
    setSelectedGroups(groupIds);
    getPosts({ searchTerm, sub_communities_ids: groupIds.join(',') });
  };

  const handleViewPost = (post) => {
    setPostDetailsState({
      visible: true,
      post,
    });
  };

  const handleClosePostDetails = () => {
    setPostDetailsState({
      visible: false,
      post: null,
    });
  };

  return (
    <PageContent title={translate('pages.connect.title.page')}>
      <div className="flex flex-row items-center justify-between">
        <div className="flex flex-row items-center gap-6">
          {/* <Select
            onChange={() => {}}
            label={translate('pages.connect.filter.select_type')}
            options={[]}
            defaultOption={{ value: 'default', label: translate('pages.connect.filter.type') }}
          /> */}

          <Select
            onChange={onChangeGroup}
            label={translate('pages.connect.filter.select_group')}
            options={groups}
            defaultOption={{ label: translate('pages.connect.filter.group') }}
            multiple={true}
          />
        </div>

        <SearchInput
          placeholder={'Search by type, theme, writer'}
          className={'w-96 mt-2'}
          onChange={handleSearchChange}
          value={searchTerm}
        />
      </div>
      <ConnectTable
        posts={posts}
        getPosts={getConnectPosts}
        type={filterType}
        toggleHidePost={toggleHidePost}
        handleViewPost={handleViewPost}
        isLoading={isLoading}
        searchTerm={queryParams.searchTerm}
      />

      {postDetailsState.visible && (
        <PostItemDetails post={postDetailsState.post} onClose={handleClosePostDetails} />
      )}
    </PageContent>
  );
};

Connect.propTypes = {
  posts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isLoading: PropTypes.bool.isRequired,
  getPosts: PropTypes.func.isRequired,
  getSuspensions: PropTypes.func.isRequired,
  getSuspicions: PropTypes.func.isRequired,
};

export default connect(
  (state) => {
    return {
      posts: ConnectSelectors.getPosts(state),
      groups: ConnectSelectors.getGroups(state),
      isLoading: ConnectSelectors.getIsLoading(state),
    };
  },
  {
    getPosts: ConnectActions.getPosts,
    getGroups: ConnectActions.getGroups,
    toggleHidePost: ConnectActions.toggleHidePost,
  },
)(Connect);
