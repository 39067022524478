import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Layout, useAuthenticated, useLogout } from 'react-admin';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from './AppBar';
import Notification from './Notification/NotificationOverride';
import Menu from './Menu';
import Sidebar from './Sidebar';

import { getCommunities, getCommunitiesSuccess } from '../../store/community/actions';

import ModalService from '../../modals/ModalService';
import ModalProvider from '../../modals/ModalProvider';

import { getIsLoading } from '../../store/ui/selectors';
import { getIsAuthorized } from '../../utils/auth';
import LocaleProvider from '../../utils/localeProvider';
import styles from './Layout.module.scss';
import { getPersistedCommunities } from '../../store/community/operations';
import localStoragePaths from '../../constants/localStoragePaths';
import { setSelectedAppName } from '../../store/auth/actions';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flex: 1,
  },
  loader: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    zIndex: 999,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

export default (props) => {
  useAuthenticated({});

  const classes = useStyles();
  const isLoading = useSelector(getIsLoading);
  const dispatch = useDispatch();
  const logout = useLogout();
  const isAuthorized = getIsAuthorized();
  const persistedCommunities = getPersistedCommunities();

  useEffect(() => {
    if (isAuthorized) {
      const selectedAppName = localStorage.getItem(localStoragePaths.SELECTED_APP_NAME);

      dispatch(getCommunities());
      dispatch(setSelectedAppName(selectedAppName || 'shareitt'));
    } else {
      logout();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthorized, persistedCommunities?.length]);

  return (
    <div className={classes.container}>
      <Layout
        {...props}
        sidebar={Sidebar}
        appBar={AppBar}
        menu={Menu}
        notification={Notification}
        className={styles.layout}
      />
      {isLoading && (
        <Backdrop className={classes.backdrop} open>
          <CircularProgress className={classes.loader} />
        </Backdrop>
      )}
      {isAuthorized && <ModalProvider ref={ref => ModalService.init(ref)} />}
      <LocaleProvider />
    </div>
  );
};
