import React, { useState } from 'react';
import Select from 'react-select';
import Button, { buttonProps } from '../../../../components/Button';

const AddTopicModal = ({ onCreateTopic, onCancel }) => {
  const [selectedTopics, setSelectedTopics] = useState([]);

  const options = [
    { value: 'take-care-dog', label: 'Take care of dog' },
    { value: 'dogs-at-home', label: 'Dogs at home' },
    { value: 'training', label: 'Training' },
  ];

  const handleChange = (selectedOptions) => {
    setSelectedTopics(selectedOptions || []);
  };

  const customStyles = {
    multiValue: (base) => ({
      ...base,
      backgroundColor: '#3E393B',
      color: '#FFFFFF',
      borderRadius: '8px',
      padding: '4px'
    }),
    multiValueLabel: (base) => ({
      ...base,
      color: '#FFFFFF',
      fontSize: '18px',
    }),
    multiValueRemove: (base) => ({
      ...base,
      color: '#FFFFFF',
      fontSize: '18px',
    }),
  };

  return (
    <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center">
      <div className="bg-white w-[30%] rounded-2xl shadow-lg flex flex-col items-center py-8">
        <span className="text-xl font-medium text-dark1">Topic</span>

        <div className="w-full px-4 mt-6">
          <Select
            isMulti
            options={options}
            value={selectedTopics}
            onChange={handleChange}
            placeholder="Add topic"
            className="react-select-container text-lg"
            classNamePrefix="react-select"
            styles={customStyles}
          />
        </div>

        <div className="flex flex-row items-center justify-end gap-2 mt-8 w-full px-4">
          <Button
            theme={buttonProps.themes.gray}
            color={buttonProps.colors.dark}
            weight={buttonProps.weights.bold}
            marginTop={false}
            size={40}
            style={{ width: '100px' }}
            onClick={onCancel}
          >
            Cancel
          </Button>

          <Button
            theme={buttonProps.themes.secondary}
            color={buttonProps.colors.light}
            weight={buttonProps.weights.bold}
            marginTop={false}
            size={40}
            style={{ width: '100px' }}
            onClick={() => onCreateTopic(selectedTopics)}
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AddTopicModal;
