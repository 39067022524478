import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDebouncedCallback as debounce } from 'use-debounce';
import { useTranslate, useLocale } from 'react-admin';
import { Link } from 'react-router-dom';
import {
  MEMBERS_INITIAL_SEARCH_TERM,
  MEMBERS_PAGE_LIMIT,
  MEMBERS_SEARCH_DELAY_TIME,
} from '../../../../../utils/members';
import { memberTypes } from '../../../../../constants/members';
import { formatDateWithLocale } from '../../../../../utils/dateFns';
import Table from '../../../../../components/Table';
import styles from './MembersTable.module.scss';
import { IMAGES_URL } from '../../../../../constants/Images';

const MembersTable = ({
  posts,
  getPosts,
  isLoading,
  type,
  searchTerm: searchTermFromQuery,
  onDeclinePost,
  onApprovePost,
}) => {
  const translate = useTranslate();
  const locale = useLocale();
  const searchRef = useRef();
  const [searchTerm, setSearchTerm] = useState(MEMBERS_INITIAL_SEARCH_TERM);

  useEffect(() => {
    if (searchTermFromQuery) {
      if (searchRef) {
        searchRef.current.value = searchTermFromQuery;
      }

      onSearchChange.callback(searchTermFromQuery);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getPosts({ searchTerm, isLoadMore: false });
  }, [getPosts, searchTerm]);

  const dateFormatter = (value) => {
    return <span className={styles.createdAt}>{formatDateWithLocale(value, locale)}</span>;
  };

  const onPageChange = (requestedPage) => {
    getPosts({ isLoadMore: true, searchTerm, nextPage: requestedPage + 1, isOnlyActive });
  };

  const onSearchChange = debounce((term) => {
    setSearchTerm(term);

    getPosts({ isLoadMore: false, searchTerm: term, isOnlyActive });
  }, MEMBERS_SEARCH_DELAY_TIME);

  const titleFormatter = (value, row) => (
    <Link to={`/knowledge-library/${row.id}`}>
      <div className={styles.postWrapper}>
        {row?.attachment && (
          <div className={styles.imageContainer}>
            <img src={row.attachment} alt={row.title} className={styles.postImage} />
          </div>
        )}
        <div className={styles.postContent}>
          <h3 className={styles.postTitle}>{row.title}</h3>
          <p className={styles.postText}>{row.content}</p>
        </div>
      </div>
    </Link>
  );

  const authorFormatter = (_, row) => {
    return (
      <Link to={`/members/${row.author.id}`}>
        <div className={styles.nameWrapper}>
          <img src={row.author.profile_image} className={styles.avatar} alt={row.author.name} />
          <span className={styles.authorName}>{row.author.name}</span>
        </div>
      </Link>
    );
  };

  const actionsFormatter = (_, row) => {
    return (
      <div className="flex flex-row items-center gap-4">
        <button
          className="bg-gray2 flex items-center justify-center px-3 py-2 rounded-lg h-[57px] hover:bg-gray10"
          onClick={() => onDeclinePost(row.id)}
        >
          <img src={IMAGES_URL.ICON_TRASH} />
        </button>
        <button
          className="bg-gray2 flex items-center justify-center px-3 py-2 text-xl font-semibold text-success rounded-lg h-[57px] hover:bg-gray10"
          onClick={() => onApprovePost(row.id)}
        >
          Approve
        </button>
      </div>
    );
  };

  const admColumns = [
    {
      dataField: 'title',
      text: translate('pages.knowledgeLibrary.table.columns.post'),
      formatter: titleFormatter,
      align: 'start',
      headerAlign: 'start',
      style: { width: '40%' },
    },
    {
      dataField: 'author',
      text: translate('pages.knowledgeLibrary.table.columns.writer'),
      formatter: authorFormatter,
      align: 'start',
      headerAlign: 'start',
    },
    {
      dataField: 'created_at',
      text: translate('pages.knowledgeLibrary.table.columns.created'),
      formatter: dateFormatter,
      align: 'start',
      headerAlign: 'start',
    },
    {
      dataField: 'actions',
      text: translate('pages.knowledgeLibrary.table.columns.actions'),
      formatter: actionsFormatter,
      align: 'start',
      headerAlign: 'start',
    },
  ];

  const pendingColumns = [...admColumns];

  const getTableColumnsByType = () => {
    if (type === memberTypes.SUSPENDED) {
      return pendingColumns;
    }

    if (type === memberTypes.SUSPICIOUS) {
      return suspicionColumns;
    }

    return admColumns;
  };

  const paginationProps = {
    onPageChange,
    sizePerPage: MEMBERS_PAGE_LIMIT,
  };

  return (
    <>
      <Table
        data={posts}
        columns={getTableColumnsByType()}
        paginationProps={paginationProps}
        loading={isLoading}
        useOverlay
      />
    </>
  );
};

MembersTable.propTypes = {
  posts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      nickname: PropTypes.string,
      profileImage: PropTypes.string,
      status: PropTypes.string,
      createdAtDate: PropTypes.string,
      lastAccessDate: PropTypes.string,
      phoneNumber: PropTypes.string,
      isShipper: PropTypes.bool,
    }),
  ).isRequired,
  isLoading: PropTypes.bool.isRequired,
  type: PropTypes.oneOf(Object.values(memberTypes)).isRequired,
  searchTerm: PropTypes.string,
  getPosts: PropTypes.func.isRequired,
};

export default MembersTable;
