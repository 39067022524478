import React, { useEffect, useState } from 'react';
import { Admin, Resource } from 'react-admin';
import jsonServerProvider from 'ra-data-json-server';
import { createBrowserHistory } from 'history';
import CssBaseline from '@material-ui/core/CssBaseline';
import i18nProvider from './i18n/i18nProvider';
import authProvider from './providers/authProvider';

import Routes, { ShareittEduRoutes } from './routes/Routes';
import { Layout } from './pages/Layout';
import LoginPage from './pages/LoginPage';
import Dashboard from './pages/Dashboard';

import { rootSaga, rootReducer } from './store';
import initialState from './constants/initialState';
import './styles/scss/theme.scss';
import './styles/index.css';
import { APIProvider } from '@vis.gl/react-google-maps';
import localStoragePaths from './constants/localStoragePaths';

const history = createBrowserHistory();
const dataProvider = jsonServerProvider('https://jsonplaceholder.typicode.com');

const API_KEY = 'AIzaSyAiECxaLCtZmmuxzo-8Hj0zFOS6kObKUsA';

const App = () => {
  const [appName, setAppName] = useState('shareitt');

  const routes = {
    shareitt: Routes,
    floweavers: ShareittEduRoutes,
  }[appName];

  useEffect(() => {
    const selectedAppName = localStorage.getItem(localStoragePaths.SELECTED_APP_NAME);

    setAppName(selectedAppName || 'shareitt');
  }, []);

  return (
    <>
      <APIProvider apiKey={API_KEY}>
        <CssBaseline />
        <Admin
          title="Shareitt Admin"
          authProvider={authProvider}
          dataProvider={dataProvider}
          i18nProvider={i18nProvider}
          loginPage={LoginPage}
          dashboard={Dashboard}
          customSagas={rootSaga}
          customReducers={rootReducer}
          layout={Layout}
          customRoutes={routes}
          initialState={initialState}
          history={history}
        >
          <Resource name="Community Info" />
        </Admin>
      </APIProvider>
    </>
  );
};

export default App;
