import { createSelector } from 'reselect';

export const getUsersLovers = (state) => {
  return state.shareittLovers.users;
};

export const getUsersAvailable = (state) => {
  return state.shareittLovers.usersAvailable;
};

export const getItemsOffset = createSelector(
  [getUsersLovers],
  (users) => {
    return users.length;
  },
);

export const getItemsExistMore = (state) => {
  return state.items.isExistMore;
};
