import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';
import { connect } from 'react-redux';
import { Authenticated, useTranslate } from 'react-admin';
import SummaryList from './List';
import PageContent from '../../components/PageContent';
import PageNavigation from '../../components/PageNavigation';
import LineChart from '../../components/Charts/LineChart';
import ColumnChart from '../../components/Charts/ColumnChart';
import DateRange from './DateRange';
import {
  getTopSoldCategoriesAnalytics,
  getTopUploadedCategoriesAnalytics,
  getItemsCountAnalytics,
  getItemsValueAnalytics,
  getSummaryList,
  getIsCommunityAnalyticsLoading,
  getCommunityAnalyticsDateRange,
  getMembersJoinedCountAnalytics,
} from '../../store/community/selectors';
import { getCommunityAnalytics as getCommunityAnalyticsAction } from '../../store/community/actions';
import styles from './Dashboard.module.scss';
import { LINE_CHART_TYPES } from '../../constants';

const Dashboard = ({
  topUploadedCategories,
  topSoldCategories,
  summaryList,
  analyticsDateRange,
  itemsCountAnalytics,
  itemsValueAnalytics,
  membersJoinedCountAnalytics,
  isLoading,
  getCommunityAnalytics,
}) => {
  const translate = useTranslate();

  return (
    <Authenticated>
      <PageContent>
        <PageNavigation className="mb-5">
          <h2 className="text-[34px] font-medium text-dark1">
            {translate('pages.dashboard.title.page')}
          </h2>

          <DateRange
            initialDateRange={analyticsDateRange}
            getCommunityAnalytics={getCommunityAnalytics}
          />
        </PageNavigation>

        <SummaryList isLoading={isLoading} list={summaryList} />

        <div className={cls('d-grid', 'mb-3', styles.lineChartsWrapper)}>
          <LineChart
            data={itemsCountAnalytics}
            type={LINE_CHART_TYPES.ITEMS}
            title={translate('pages.dashboard.itemsCountAnalytics')}
            isLoading={isLoading}
          />
          <LineChart
            data={itemsValueAnalytics}
            type={LINE_CHART_TYPES.ITEMS}
            title={translate('pages.dashboard.itemsValueAnalytics')}
            isLoading={isLoading}
          />
          <LineChart
            data={membersJoinedCountAnalytics}
            type={LINE_CHART_TYPES.MEMBERS}
            title={translate('pages.dashboard.membersJoinedCountAnalytics')}
            isLoading={isLoading}
          />
        </div>

        <div className={cls('d-grid', styles.columnChartsWrapper)}>
          <ColumnChart
            data={topUploadedCategories}
            title={translate('pages.dashboard.topUploadedCategories')}
            isLoading={isLoading}
          />
          <ColumnChart
            data={topSoldCategories}
            title={translate('pages.dashboard.topSoldCategories')}
            isLoading={isLoading}
          />
        </div>
      </PageContent>
    </Authenticated>
  );
};

Dashboard.propTypes = {
  topUploadedCategories: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      y: PropTypes.number,
    }),
  ).isRequired,
  topSoldCategories: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      y: PropTypes.number,
    }),
  ).isRequired,
  summaryList: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number,
    }),
  ).isRequired,
  analyticsDateRange: PropTypes.shape({
    startDate: PropTypes.string,
    endDate: PropTypes.string,
  }).isRequired,
  itemsCountAnalytics: PropTypes.arrayOf(PropTypes.array).isRequired,
  itemsValueAnalytics: PropTypes.arrayOf(PropTypes.array).isRequired,
  membersJoinedCountAnalytics: PropTypes.arrayOf(PropTypes.array).isRequired,
  isLoading: PropTypes.bool.isRequired,
  getCommunityAnalytics: PropTypes.func.isRequired,
};

export default connect(
  (state) => {
    return {
      summaryList: getSummaryList(state),
      analyticsDateRange: getCommunityAnalyticsDateRange(state),
      topUploadedCategories: getTopUploadedCategoriesAnalytics(state),
      topSoldCategories: getTopSoldCategoriesAnalytics(state),
      itemsCountAnalytics: getItemsCountAnalytics(state),
      itemsValueAnalytics: getItemsValueAnalytics(state),
      membersJoinedCountAnalytics: getMembersJoinedCountAnalytics(state),
      isLoading: getIsCommunityAnalyticsLoading(state),
    };
  },
  {
    getCommunityAnalytics: getCommunityAnalyticsAction,
  },
)(Dashboard);
