import React, { useState } from 'react';
import { useTranslate } from 'react-admin';
import ImageUploader from '../../../components/ImageUploader';
import imageUploaderApi from '../../../domains/ImageUploader/imageUploader.api';
import './style.css';
import Button, { buttonProps } from '../../../components/Button';
import { connect } from 'react-redux';
import * as shareittLoversActions from '../../../store/shareittLovers/actions';
import * as communityActions from '../../../store/community/actions';
import { getCurrentCommunity } from '../../../store/community/selectors';

const EmblemPage = ({ updateCommunity, getCommunities, currentCommunity }) => {
  const [backgroundImage, setBackgroundImage] = useState(
    currentCommunity?.communityLoversConfig?.emblem || '',
  );
  const [hexColor, setHexColor] = useState(
    currentCommunity?.communityLoversConfig?.color || '#000000',
  );
  const [opacity, setOpacity] = useState(100);
  const [name, setName] = useState(
    currentCommunity?.communityLoversConfig?.name || '',
  );

  const translate = useTranslate();

  async function onChangeImage(image) {
    const formData = new FormData();

    formData.append('image', image);
    const link = await imageUploaderApi.uploadImage(formData);

    setBackgroundImage(link);
  }

  const handleSubmit = () => {
    updateCommunity({
      communityLoversConfig: {
        emblem: backgroundImage,
        color: hexColor,
        name: name,
      },
    });

    getCommunities();
  };

  return (
    <div className="flex flex-col w-full h-full justify-center">
      <ImageUploader defaultValue={backgroundImage} onChange={onChangeImage} />

      <span className="text-lg font-medium text-black font-rubik pt-6">
        {translate('pages.shareitt_lovers.emblem_page.color')}
      </span>
      <div className="flex items-center p-2 rounded-lg bg-gray-100 space-x-2 max-w-[210px]">
        <div className="colorpicker border-gray6" style={{ backgroundColor: hexColor }}>
          <input
            type="color"
            value={hexColor}
            onChange={(e) => setHexColor(e.target.value)}
            className="w-8 h-8 cursor-pointer"
            style={{ border: 'none' }}
          />
        </div>

        <span className="pr-5">{hexColor?.replace('#', '')?.toUpperCase()}</span>

        <div className="h-6 w-1 border-l border-white"></div>

        <div className="flex flex-row items-center justify-between flex-1">
          <span>{opacity}</span>
          <span>{translate('pages.shareitt_lovers.emblem_page.percent')}</span>
        </div>
      </div>

      <span className="text-lg font-medium text-black font-rubik pt-6">
        {translate('pages.shareitt_lovers.emblem_page.name')}
      </span>
      <input
        className="border-1 border-gray2 rounded-lg p-2 w-full h-[40px]"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />

      <div className="flex flex-row items-center justify-end gap-2 mt-8">
        <Button
          theme={buttonProps.themes.secondary}
          color={buttonProps.colors.light}
          weight={buttonProps.weights.bold}
          marginTop={false}
          size={40}
          style={{ width: '100px' }}
          onClick={handleSubmit}
        >
          {translate('general.button.save')}
        </Button>

        <Button
          theme={buttonProps.themes.gray}
          color={buttonProps.colors.dark}
          weight={buttonProps.weights.bold}
          marginTop={false}
          size={40}
          style={{ width: '100px' }}
        >
          {translate('general.button.cancel')}
        </Button>
      </div>
    </div>
  );
};

export default connect(
  (state) => ({
    currentCommunity: getCurrentCommunity(state),
  }),
  {
    updateCommunity: shareittLoversActions.updateCommunity,
    getCommunities: communityActions.getCommunities,
  },
)(EmblemPage);
