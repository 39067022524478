import { all, takeEvery, call, put, select } from 'redux-saga/effects';
import { showNotification } from 'react-admin';
import api from '../../api/shareittLovers';
import { getSelectedCommunityId } from '../community/selectors';
import * as AT from './types';
import * as actions from './actions';
import * as selectors from './selectors';
import { DEFAULT_SEARCH_TERM, ITEMS_INITIAL_LIMIT, ITEMS_PAGE_LIMIT } from '../../constants/items';

function* onGetUsersLovers({ payload = {} }) {
  try {
    const {
      isLoadMore = true,
      searchTerm = DEFAULT_SEARCH_TERM,
      nextPage = 0,
      onlyPending = false,
    } = payload;

    const communityId = yield select(getSelectedCommunityId);
    const currentItemsOffset = yield select(selectors.getItemsOffset);
    const isExistMore = yield select(selectors.getItemsExistMore);
    const isNeedToFetchData = currentItemsOffset <= nextPage * ITEMS_PAGE_LIMIT;

    if (isLoadMore && (!isExistMore || !isNeedToFetchData)) {
      return;
    }

    yield put(actions.setIsLoading(true));

    const skip = isLoadMore ? currentItemsOffset : 0;
    const limit = skip === 0 ? ITEMS_INITIAL_LIMIT : ITEMS_PAGE_LIMIT;

    const users = yield call(api.geUsersLovers, {
      communityId,
      skip,
      limit,
      searchTerm,
      onlyPending,
    });

    if (onlyPending) {
      yield put(
        actions.getUsersAvailableSuccess({
          usersAvailable: users,
          isLoadMore,
          isExistMore: users.length === limit,
        }),
      );

      return;
    }
    yield put(
      actions.getUsersLoversSuccess({
        users,
        isLoadMore,
        isExistMore: users.length === limit,
      }),
    );
  } catch (error) {
    yield put(showNotification('message.error.fetch_users_love', 'error'));
  }
}

function* onAddUserLover({ payload }) {
  try {
    const selectedCommunityId = yield select(getSelectedCommunityId);
    yield call(api.addUserLover, selectedCommunityId, payload);
    yield put(actions.addUserLoverSuccess());
    yield put(showNotification('message.success.add_user_love', 'info'));

    const usersAvailable = yield select(selectors.getUsersAvailable);
    const updatedUsersAvailable = usersAvailable.filter((user) => user.id !== payload);
    yield put(
      actions.getUsersAvailableSuccess({
        usersAvailable: updatedUsersAvailable,
        isLoadMore: false,
        isExistMore: true,
      }),
    );
  } catch (error) {
    yield put(showNotification('message.error.add_user_love', 'error'));
  }
}

function* onRemoveUserLover({ payload }) {
  try {
    const selectedCommunityId = yield select(getSelectedCommunityId);
    yield call(api.removeUserLover, selectedCommunityId, payload);
    yield put(actions.removeUserLoverSuccess());
    yield put(showNotification('message.success.remove_user_love', 'info'));
    yield put(actions.getUsersLovers({ isLoadMore: false, nextPage: 0 }));
  } catch (error) {
    yield put(showNotification('message.error.remove_user_love', 'error'));
  }
}

function* onUpdateCommunity({ payload }) {
  try {
    const selectedCommunityId = yield select(getSelectedCommunityId);
    yield call(api.updateCommunityLover, selectedCommunityId, payload);
    yield put(actions.updateCommunitySuccess());
    yield put(showNotification('message.success.update_community', 'info'));
  }
  catch (error) {
    yield put(showNotification('message.error.update_community', 'error'));
  }
}

export default function* rootSaga() {
  yield all([takeEvery(AT.GET_USERS_LOVERS, onGetUsersLovers)]);
  yield all([takeEvery(AT.ADD_USER_LOVER, onAddUserLover)]);
  yield all([takeEvery(AT.REMOVE_USER_LOVER, onRemoveUserLover)]);
  yield all([takeEvery(AT.UPDATE_COMMUNITY, onUpdateCommunity)]);
}
