import { createAction } from 'redux-actions';
import * as AT from './types';

export const getPosts = createAction(AT.GET_POSTS);
export const getPostsSuccess = createAction(AT.GET_POSTS_SUCCESS);

export const approvePost = createAction(AT.APPROVE_POST);
export const approvePostSuccess = createAction(AT.APPROVE_POST_SUCCESS);

export const toggleHidePost = createAction(AT.TOGGLE_HIDE_POST);
export const toggleHidePostSuccess = createAction(AT.TOGGLE_HIDE_POST_SUCCESS);

export const getGroups = createAction(AT.GET_GROUPS);
export const getGroupsSuccess = createAction(AT.GET_GROUPS_SUCCESS);

export const setIsLoading = createAction(AT.SET_IS_LOADING);
