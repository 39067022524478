const prefix = 'knowledgeLibrary/';

export const GET_PENDING_POSTS = `${prefix}GET_PENDING_POSTS`;
export const GET_PENDING_POSTS_SUCCESS = `${prefix}GET_PENDING_POSTS_SUCCESS`;
export const GET_APPROVED_POSTS = `${prefix}GET_APPROVED_POSTS`;
export const GET_APPROVED_POSTS_SUCCESS = `${prefix}GET_APPROVED_POSTS_SUCCESS`;
export const APPROVE_POST = `${prefix}APPROVE_POST`;
export const APPROVE_POST_SUCCESS = `${prefix}APPROVE_POST_SUCCESS`;
export const REJECT_POST = `${prefix}REJECT_POST`;
export const REJECT_POST_SUCCESS = `${prefix}REJECT_POST_SUCCESS`;
export const CREATE_TOPIC = `${prefix}CREATE_TOPIC`;
export const CREATE_TOPIC_SUCCESS = `${prefix}CREATE_TOPIC_SUCCESS`;
export const TOGGLE_HIDE_POST = `${prefix}TOGGLE_HIDE_POST`;
export const TOGGLE_HIDE_POST_SUCCESS = `${prefix}TOGGLE_HIDE_POST_SUCCESS`;
export const GET_TOPICS = `${prefix}GET_TOPICS`;
export const GET_TOPICS_SUCCESS = `${prefix}GET_TOPICS_SUCCESS`;
