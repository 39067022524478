// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/**\n * Custom application mixins available through out the app\n */\n/* Chat Scrollbar */\n/*\n * Typography\n * ======================================================================== */\ninput[type=tel],\ninput[type=number] {\n  direction: ltr !important;\n}\n\n.Select_selectWrapper__2UFD0 {\n  display: flex !important;\n  max-width: 200px;\n  width: 200px;\n  height: 50px;\n}\n\n.Select_selectMenu__1JM6e {\n  width: 100%;\n  max-width: 200px;\n  overflow: hidden;\n  overflow-y: scroll;\n  height: 200px;\n}\n\n.Select_selectPlaceholder__2fms3 {\n  display: flex !important;\n  flex-flow: row nowrap;\n  justify-content: space-between;\n  align-items: center;\n  color: #494A4C !important;\n  background-color: #F5F6F7 !important;\n  border: 1px solid #BFC0C2 !important;\n  font-weight: 600 !important;\n}\n\n.Select_caret__188vN {\n  font-size: 2rem !important;\n}\n\n.Select_emptyImage__2vO6y {\n  border-radius: 50%;\n  background-color: #798892;\n}\n\n.Select_selectedChip__1rVWi {\n  display: flex;\n  align-items: center;\n  background-color: #007bff;\n  color: #fff;\n  padding: 0.25rem 0.5rem;\n  border-radius: 15px;\n  margin-right: 5px;\n}\n\n.Select_removeButton__3uWPB {\n  background: none;\n  border: none;\n  color: #fff;\n  margin-left: 5px;\n  cursor: pointer;\n}\n\n.Select_placeholderText__1UrQG {\n  color: #999;\n}", ""]);
// Exports
exports.locals = {
	"selectWrapper": "Select_selectWrapper__2UFD0",
	"selectMenu": "Select_selectMenu__1JM6e",
	"selectPlaceholder": "Select_selectPlaceholder__2fms3",
	"caret": "Select_caret__188vN",
	"emptyImage": "Select_emptyImage__2vO6y",
	"selectedChip": "Select_selectedChip__1rVWi",
	"removeButton": "Select_removeButton__3uWPB",
	"placeholderText": "Select_placeholderText__1UrQG"
};
module.exports = exports;
