import axios from 'axios';
import qs from 'query-string';
import { prop, path } from 'ramda';
import { BASE_API_URL } from '../config/urls';
import PATHS from '../constants/localStoragePaths';

const getIs401Error = (error) => {
  return path(['response', 'status'], error) === 401;
};

const axiosDefaults = axios.defaults;
const { common } = axiosDefaults.headers;

/* eslint-disable no-console */
class Api {
  constructor(baseURL) {
    axiosDefaults.baseURL = baseURL;
    axiosDefaults.withCredentials = true;

    common.Timezone = -new Date().getTimezoneOffset() / 60;
  }

  getQueryParamsFromObject(queryParams) {
    return qs.stringify(queryParams);
  }

  forceLogout = () => {
    //localStorage.removeItem(PATHS.IS_AUTHORIZED);
  };

  async post(url, data) {
    return axios
      .post(url, data)
      .then(prop('data'))
      .catch(async (error) => {
        if (getIs401Error(error)) {
          this.forceLogout();
        }

        console.warn('RESPONSE ERROR -->> ', url, error.response.status, error.response.data);
        throw error;
      });
  }

  async put(url, data) {
    return axios
      .put(url, data)
      .then(prop('data'))
      .catch(async (error) => {
        if (getIs401Error(error)) {
          this.forceLogout();
        }

        console.warn('RESPONSE ERROR -->> ', url, error.response.status, error.response.data);
        throw error;
      });
  }

  async patch(url, data) {
    return axios
      .patch(url, data)
      .then(prop('data'))
      .catch(async (error) => {
        if (getIs401Error(error)) {
          this.forceLogout();
        }

        console.warn('RESPONSE ERROR -->> ', url, error.response.status, error.response.data);
        throw error;
      });
  }

  async get(url) {
    return axios
      .get(url)
      .then(prop('data'))
      .catch(async (error) => {
        if (getIs401Error(error)) {
          this.forceLogout();
        }

        console.warn('RESPONSE ERROR -->> ', url, error.response.status, error.response.data);
        throw error;
      });
  }

  async delete(url) {
    return axios
      .delete(url)
      .then(prop('data'))
      .catch(async (error) => {
        if (getIs401Error(error)) {
          this.forceLogout();
        }

        console.warn('RESPONSE ERROR -->> ', url, error.response.status, error.response.data);
        throw error;
      });
  }
}

const api = new Api(BASE_API_URL);

export default api;
