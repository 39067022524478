import React, { useState, useRef } from 'react';
import { useTranslate } from 'react-admin';
import { ReactComponent as MoreVertIcon } from '../../../../assets/icons/more-vertical.svg';
import Tooltip from '../../../../components/Tooltip';

export const CriteriaItem = ({
  title,
  inputValue,
  onInputChange,
  onDesactivateCriteria,
  onActivateCriteria,
  isActive,
}) => {
  const [position, setPosition] = useState({ top: 0, left: 0 });
  const [visiblePopover, setVisiblePopover] = useState(false);
  const buttonRef = useRef(null);
  const translate = useTranslate();

  const togglePopover = (event) => {
    const rect = event.currentTarget.getBoundingClientRect();
    setPosition({ top: rect.bottom, left: rect.left });
    setVisiblePopover((prev) => !prev);
  };

  return (
    <div className="flex flex-row justify-between w-full items-center mb-6">
      <div className="flex flex-row items-center gap-2">
        <span className="text-lg font-medium text-dark1">{title}</span>
        <span className="text-red2">
          {!isActive && translate('pages.shareitt_lovers.disabled')}
        </span>
      </div>

      <div className="flex flex-row items-center gap-20">
        <input
          className="w-[120px] h-[54px] border-1 border-gray2 bg-gray3 text-center text-lg"
          type={title === translate('pages.shareitt_lovers.criteria_page.minimum_created_at') ? 'date' : 'text'}
          value={inputValue || ''}
          onChange={onInputChange}
        />

        <button ref={buttonRef} className="flex items-center" onClick={togglePopover}>
          <MoreVertIcon className="text-bluePrimary" />
        </button>

        {visiblePopover && (
          <Tooltip
            visible={visiblePopover}
            position={position}
            onClose={() => setVisiblePopover(false)}
          >
            <div className="flex flex-col">
              <button
                className="w-full text-left px-4 py-2 text-[18px] text-red2 hover:bg-gray-100"
                onClick={() => {
                  if (isActive) {
                    onDesactivateCriteria();
                  } else {
                    onActivateCriteria();
                  }
                  setVisiblePopover(false);
                }}
              >
                {!isActive
                  ? translate('pages.shareitt_lovers.activate')
                  : translate('pages.shareitt_lovers.desactivate')}
              </button>
            </div>
          </Tooltip>
        )}
      </div>
    </div>
  );
};
