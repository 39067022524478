import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDebouncedCallback as debounce } from 'use-debounce';
import { useTranslate, useLocale } from 'react-admin';
import { Link } from 'react-router-dom';
import {
  MEMBERS_INITIAL_SEARCH_TERM,
  MEMBERS_PAGE_LIMIT,
  MEMBERS_SEARCH_DELAY_TIME,
} from '../../../utils/members';
import { memberTypes } from '../../../constants/members';
import { formatDateWithLocale } from '../../../utils/dateFns';
import Table from '../../../components/Table';
import styles from './ShareittLoversTable.module.scss';
import { IMAGES_URL } from '../../../constants/Images';

const ShareittLoversTable = ({
  posts,
  getPosts,
  isLoading,
  type,
  searchTerm: searchTermFromQuery,
  onRemoveUser,
}) => {
  const translate = useTranslate();
  const searchRef = useRef();
  const [searchTerm, setSearchTerm] = useState(MEMBERS_INITIAL_SEARCH_TERM);

  useEffect(() => {
    if (searchTermFromQuery) {
      if (searchRef) {
        searchRef.current.value = searchTermFromQuery;
      }

      onSearchChange.callback(searchTermFromQuery);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getPosts({ searchTerm, isLoadMore: false });
  }, [getPosts, searchTerm]);

  const phoneNumberFormatter = (value, row) => {
    return <span className={styles.memberId}>{row.id}</span>;
  };

  const onPageChange = (requestedPage) => {
    getPosts({ isLoadMore: true, searchTerm, nextPage: requestedPage + 1 });
  };

  const onSearchChange = debounce((term) => {
    setSearchTerm(term);

    getPosts({ isLoadMore: false, searchTerm: term });
  }, MEMBERS_SEARCH_DELAY_TIME);

  const userFormatter = (value, row) => (
    <Link to={`/members/${row.id}`}>
      <div className={styles.nameWrapper}>
        <img src={row.profile_image} className={styles.avatar} alt={row.name} />
        <span className={styles.authorName}>{row.name}</span>
      </div>
    </Link>
  );

  const memberIdFormatter = (value, row) => {
    return <span className={styles.memberId}>{row.id}</span>;
  };

  const deletFormatter = (value, row) => {
    return (
      <button
        className="bg-gray2 flex items-center justify-center px-3 py-2 rounded-lg h-[57px] hover:bg-gray10"
        onClick={() => onRemoveUser(row.id)}
      >
        <img src={IMAGES_URL.ICON_TRASH} />
      </button>
    );
  };

  const admColumns = [
    {
      dataField: 'user',
      text: translate('pages.shareitt_lovers.table.columns.user'),
      formatter: userFormatter,
      align: 'start',
      headerAlign: 'start',
      style: { width: '40%' },
    },
    {
      dataField: 'member_id',
      text: translate('pages.shareitt_lovers.table.columns.member_id'),
      formatter: memberIdFormatter,
      align: 'start',
      headerAlign: 'start',
    },
    {
      dataField: 'phone_number',
      text: translate('pages.shareitt_lovers.table.columns.phone_number'),
      formatter: phoneNumberFormatter,
      align: 'start',
      headerAlign: 'start',
    },
    {
      dataField: 'delet',
      text: translate('pages.shareitt_lovers.table.columns.delet'),
      formatter: deletFormatter,
      align: 'start',
      headerAlign: 'start',
    },
  ];

  const pendingColumns = [...admColumns];

  const getTableColumnsByType = () => {
    if (type === memberTypes.SUSPENDED) {
      return pendingColumns;
    }

    if (type === memberTypes.SUSPICIOUS) {
      return suspicionColumns;
    }

    return admColumns;
  };

  const paginationProps = {
    onPageChange,
    sizePerPage: MEMBERS_PAGE_LIMIT,
  };

  return (
    <>
      <Table
        data={posts}
        columns={getTableColumnsByType()}
        paginationProps={paginationProps}
        loading={isLoading}
        useOverlay
      />
    </>
  );
};

ShareittLoversTable.propTypes = {
  posts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      nickname: PropTypes.string,
      profileImage: PropTypes.string,
      status: PropTypes.string,
      createdAtDate: PropTypes.string,
      lastAccessDate: PropTypes.string,
      phoneNumber: PropTypes.string,
      isShipper: PropTypes.bool,
    }),
  ).isRequired,
  isLoading: PropTypes.bool.isRequired,
  type: PropTypes.oneOf(Object.values(memberTypes)).isRequired,
  searchTerm: PropTypes.string,
  getPosts: PropTypes.func.isRequired,
};

export default ShareittLoversTable;
