import api from './Api';

const getPosts = (communityId, title, sub_communities_ids) => {
  const queryParams = new URLSearchParams();

  if (title) queryParams.append('title', title);
  if (sub_communities_ids) queryParams.append('sub_communities_ids', sub_communities_ids);

  const url = `/v2/admin/feed/community/${communityId}?${queryParams.toString()}`;
  return api.get(url);
};

const toggleHidePost = (postId) => {
  // todo: fix typo toogle-hide to toggle-hide
  return api.patch(`/v2/admin/feed/${postId}/toogle-hide`);
};

export default {
  getPosts,
  toggleHidePost,
};
