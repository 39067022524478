import api from './Api';

const getPosts = (communityId, isPending, title, topics) => {
  const queryParams = new URLSearchParams();

  if (title) queryParams.append('title', title);
  if (topics?.length > 0) queryParams.append('topics', topics);

  return api.get(`/v2/admin/knowledge-library/community/${communityId}?is_approved=${!isPending}&${queryParams.toString()}`);
};

const approvePost = (id) => {
  return api.patch(`/v2/admin/knowledge-library/${id}/approve`);
};

const rejectPost = (id) => {
  return api.patch(`/v2/admin/knowledge-library/${id}/reject`);
}

const toggleHidePost = (postId) => {
  // todo: fix typo toogle-hide to toggle-hide
  return api.patch(`/v2/admin/knowledge-library/${postId}/toogle-hide`);
};

const createTopic = (data) => {
  return api.post(`/v2/admin/knowledge-library/topics`, data);
};

const getTopics = (communityId, searchTerm) => {
  const queryParams = new URLSearchParams();
  if (searchTerm) queryParams.append('topic', searchTerm);

  return api.get(`/v2/admin/knowledge-library/topics/${communityId}?${queryParams.toString()}`);
};

export default {
  getPosts,
  approvePost,
  rejectPost,
  createTopic,
  toggleHidePost,
  getTopics,
};
