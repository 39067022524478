import React, { memo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';
import {
  FormGroup,
  Label,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledButtonDropdown,
} from 'reactstrap';
import styles from './Select.module.scss';

const Select = memo(({ options, onChange, label, id, className, defaultOption, multiple }) => {
  const [selectedOptions, setSelectedOptions] = useState(multiple ? [] : defaultOption || null);

  const onSelect = (option) => {
    if (multiple) {
      const newSelectedOptions = selectedOptions.includes(option)
        ? selectedOptions.filter((selected) => selected !== option)
        : [...selectedOptions, option];
      setSelectedOptions(newSelectedOptions);
      onChange(newSelectedOptions);
    } else {
      setSelectedOptions(option);
      onChange(option);
    }
  };

  const removeSelectedOption = (option) => {
    const updatedOptions = selectedOptions.filter((selected) => selected !== option);
    setSelectedOptions(updatedOptions);
    onChange(updatedOptions);
  };

  const isSelectDisabled = options.length === 0;

  useEffect(() => {
    if (!selectedOptions && options.length > 0 && !multiple) {
      onSelect(options[0]);
    }
  }, [options, selectedOptions, multiple]);

  return (
    <FormGroup className={className}>
      {label && (
        <Label for={id} className="font-weight-bold">
          {label}
        </Label>
      )}
      <div className="d-flex align-items-center gap-2">
        <UncontrolledButtonDropdown id={id} className={styles.selectWrapper}>
          <DropdownToggle
            className={cls(styles.selectPlaceholder, 'rounded')}
            disabled={isSelectDisabled}
            color="default"
            size="lg"
          >
            Select...
            <b className={cls('fa', 'fa-angle-down', styles.caret)} />
          </DropdownToggle>
          <DropdownMenu className={styles.selectMenu}>
            {options.map((option) => (
              <DropdownItem onClick={() => onSelect(option)} key={option.value}>
                {option.icon && (
                  <img
                    width={25}
                    height={25}
                    src={option.icon}
                    alt=""
                    className={cls('mr-3', {
                      [styles.emptyImage]: !option.icon,
                    })}
                  />
                )}
                {option.label}
              </DropdownItem>
            ))}
          </DropdownMenu>
        </UncontrolledButtonDropdown>

        {multiple && selectedOptions.length > 0 && (
          <div className="d-flex flex-wrap gap-2 ms-auto">
            {selectedOptions.map((option) => (
              <div
                key={option.value}
                className={cls(
                  'px-3 d-flex h-[50px] items-center justify-center rounded bg-bluePrimary text-white text-lg font-medium',
                  styles.chip,
                )}
              >
                {option.label}
                <span
                  className="ml-2 cursor-pointer text-white text-xl"
                  onClick={() => removeSelectedOption(option)}
                >
                  &times;
                </span>
              </div>
            ))}
          </div>
        )}
      </div>
    </FormGroup>
  );
});

Select.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      label: PropTypes.string.isRequired,
      icon: PropTypes.string,
    }),
  ).isRequired,
  label: PropTypes.string,
  id: PropTypes.string,
  className: PropTypes.string,
  defaultOption: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    label: PropTypes.string.isRequired,
    icon: PropTypes.string,
  }),
  onChange: PropTypes.func.isRequired,
  multiple: PropTypes.bool,
};

Select.defaultProps = {
  label: null,
  id: null,
  className: null,
  defaultOption: null,
  multiple: false,
};

export default Select;
