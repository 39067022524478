import { handleActions } from 'redux-actions';
import { CLEAR_STATE } from 'react-admin';
import * as AT from './types';

const initialState = {
  users: [],
  usersAvailable: [],
  isExistMore: true,
};

const reducer = handleActions(
  {
    [AT.GET_USERS_LOVERS_SUCCESS]: (state, action) => ({
      ...state,
      users: state.users.concat(action.payload.users),
      isExistMore: action.payload.isExistMore,
    }),
    [AT.GET_USERS_AVAILABLE_SUCCESS]: (state, action) => ({
      ...state,
      usersAvailable: state.usersAvailable.concat(action.payload.usersAvailable),
      isExistMore: action.payload.isExistMore,
    }),
  },
  initialState,
);

export default reducer;
