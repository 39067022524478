const prefix = 'shareittLovers/';

export const GET_USERS_LOVERS = `${prefix}GET_USERS_LOVERS`;
export const GET_USERS_LOVERS_SUCCESS = `${prefix}GET_USERS_LOVERS_SUCCESS`;

export const ADD_USER_LOVER = `${prefix}ADD_USER_LOVER`;
export const ADD_USER_LOVER_SUCCESS = `${prefix}ADD_USER_LOVER_SUCCESS`;

export const REMOVE_USER_LOVER = `${prefix}REMOVE_USER_LOVER`;
export const REMOVE_USER_LOVER_SUCCESS = `${prefix}REMOVE_USER_LOVER_SUCCESS`;

export const SET_IS_LOADING = `${prefix}SET_IS_LOADING`;

export const GET_USERS_AVAILABLE = `${prefix}GET_USERS_AVAILABLE`;
export const GET_USERS_AVAILABLE_SUCCESS = `${prefix}GET_USERS_AVAILABLE_SUCCESS`;

export const UPDATE_COMMUNITY = `${prefix}UPDATE_COMMUNITY`;
export const UPDATE_COMMUNITY_SUCCESS = `${prefix}UPDATE_COMMUNITY_SUCCESS`;
