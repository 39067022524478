import React, { useEffect, useState } from 'react';
import { useTranslate } from 'react-admin';
import KnowledgeLibraryTable from './components/KnowledgeLibraryTable';

import * as knowledgeLibraryActions from '../../../store/knowledgeLibrary/actions';
import * as knowledgeLibrarySelectors from '../../../store/knowledgeLibrary/selectors';
import { connect } from 'react-redux';
import Select from '../../../components/Select';
import SearchInput from '../../../components/Search';
import PostItemDetails from '../components/PostItemDetails';
import AddTopicModal from '../components/AddTopicModal';

const AdminPage = ({ posts, getApprovedPosts, toggleHidePost, getTopics, topics }) => {
  const translate = useTranslate();
  const [selectedTopics, setSelectedTopics] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  const [postDetailsState, setPostDetailsState] = useState({
    visible: false,
    post: null,
  });

  const [addTopicModalVisible, setAddTopicModalVisible] = useState(false);

  const makeGetPostsFunc = () => {
    return getApprovedPosts;
  };

  const getLibraryPosts = makeGetPostsFunc();

  const handleToggleHidePost = (postId) => {
    toggleHidePost(postId);
  };

  const handleViewPost = (post) => {
    setPostDetailsState({
      visible: true,
      post,
    });
  };

  const handleClosePostDetails = () => {
    setPostDetailsState({
      visible: false,
      post: null,
    });
  };

  const handleAddTopic = (post) => {
    setAddTopicModalVisible(true);
  };

  useEffect(() => {
    getTopics();
  }, []);

  const onChangeTopic = (selectedOptions) => {
    const topicsIds = selectedOptions.map((option) => option.value);
    setSelectedTopics(topicsIds);
    getApprovedPosts({
      topics: topicsIds.join(','),
      searchTerm,
    });
  };

  const handleSearchChange = (value) => {
    setSearchTerm(value);
    getApprovedPosts({
      topics: selectedTopics.join(','),
      searchTerm: value,
    });
  };

  return (
    <div>
      <div className="flex flex-row items-center justify-between">
        <div className="flex flex-row items-center gap-6">
          <Select
            onChange={onChangeTopic}
            label={translate('pages.knowledgeLibrary.filter.select_topic')}
            options={topics.map((topic) => ({ value: topic.id, label: topic.topic }))}
            defaultOption={{ label: translate('pages.connect.filter.group') }}
            multiple={true}
          />
        </div>

        <SearchInput
          placeholder={'Search by type, theme, writer'}
          className={'w-96 mt-2'}
          onChange={handleSearchChange}
          value={searchTerm}
        />
      </div>
      <KnowledgeLibraryTable
        posts={posts}
        getPosts={getLibraryPosts}
        toggleHidePost={handleToggleHidePost}
        handleViewPost={handleViewPost}
        handleAddTopic={handleAddTopic}
        searchTerm={searchTerm}
      />

      {postDetailsState.visible && (
        <PostItemDetails post={postDetailsState.post} onClose={handleClosePostDetails} />
      )}

      {addTopicModalVisible && <AddTopicModal />}
    </div>
  );
};

export default connect(
  (state) => {
    return {
      posts: knowledgeLibrarySelectors.getApprovedPosts(state),
      topics: knowledgeLibrarySelectors.getTopics(state),
    };
  },
  {
    getApprovedPosts: knowledgeLibraryActions.getApprovedPosts,
    toggleHidePost: knowledgeLibraryActions.toggleHidePost,
    getTopics: knowledgeLibraryActions.getTopics,
  },
)(AdminPage);
