import { all, takeEvery, call, put, select } from 'redux-saga/effects';
import { showNotification } from 'react-admin';
import api from '../../api/connect';
import { getSelectedCommunityId } from '../community/selectors';
import * as AT from './types';
import * as actions from './actions';
import subcommunitiesApi from '../../domains/Subcommunities/subcommunities.api';

function* onGetPosts({ payload }) {
  try {
    const selectedCommunityId = yield select(getSelectedCommunityId);
    yield put(actions.setIsLoading(true));
    const posts = yield call(api.getPosts, selectedCommunityId, payload?.searchTerm, payload?.sub_communities_ids);
    yield put(actions.getPostsSuccess(posts));
  } catch (error) {
    yield put(showNotification('Error fetching posts', 'error'));
  } finally {
    yield put(actions.setIsLoading(false));
  }
}

function* onToggleHidePost({ payload: postId }) {
  try {
    yield call(api.toggleHidePost, postId);
    yield put(actions.toggleHidePostSuccess(postId));
    yield put(showNotification('Post hidden successfully', 'info'));
    yield put(actions.getPosts());
  } catch (error) {
    yield put(showNotification('Error hiding post', 'error'));
  }
}

function* onGetGroups() {
  try {
    const selectedCommunityId = yield select(getSelectedCommunityId);

    const groups = yield call(subcommunitiesApi.getSubcommunities, selectedCommunityId);

    const updatedGroups = groups.map((group) => ({
      ...group,
      label: group.name,
    }));

    yield put(actions.getGroupsSuccess(updatedGroups));
  } catch (error) {
    yield put(showNotification('Error fetching groups', 'error'));
  }
}

export default function* rootSaga() {
  yield all([takeEvery(AT.GET_POSTS, onGetPosts)]);
  yield all([takeEvery(AT.TOGGLE_HIDE_POST, onToggleHidePost)]);
  yield all([takeEvery(AT.GET_GROUPS, onGetGroups)]);
}
