import authReducer from './auth/reducer';
import communityReducer from './community/reducer';
import uiReducer from './ui/reducer';
import rulesReducer from './rules/reducer';
import featuresReducer from './features/reducer';
import categoriesReducer from './categories/reducer';
import brandsReducer from './brands/reducer';
import conditionsReducer from './conditions/reducer';
import sizesReducer from './sizes/reducer';
import validationReducer from './validation/reducer';
import membersReducer from './members/reducer';
import transactionsReducer from './transactions/reducer';
import reportsReducer from './reports/reducer';
import itemsReducer from './items/reducer';
import walletsReducer from './wallets/reducer';
import citiesReducer from './cities/reducer';
import talentsReducer from './talents/reducer';
import subcommunitiesReducer from './subcommunities/reducer';
import knowledgeLibraryReducer from './knowledgeLibrary/reducer';
import connectReducer from './connect/reducer';
import shareittLoversReducer from './shareittLovers/reducer';

import authSaga from './auth/operations';
import communitySaga from './community/operations';
import rulesSaga from './rules/operations';
import featuresSaga from './features/operations';
import categoriesSaga from './categories/operations';
import brandsSaga from './brands/operations';
import conditionsSaga from './conditions/operations';
import sizesSaga from './sizes/operations';
import validationSaga from './validation/operations';
import membersSaga from './members/operations';
import transactionsSaga from './transactions/operations';
import reportsSaga from './reports/operations';
import itemsSaga from './items/operations';
import walletsSaga from './wallets/operations';
import citiesSaga from './cities/operations';
import talentsSaga from './talents/operations';
import subcommunitiesSaga from './subcommunities/operations';
import knowledgeLibrarySaga from './knowledgeLibrary/operations';
import connectSaga from './connect/operations';
import shareittLoversSaga from './shareittLovers/operations';

export const rootReducer = {
  auth: authReducer,
  community: communityReducer,
  ui: uiReducer,
  rules: rulesReducer,
  features: featuresReducer,
  categories: categoriesReducer,
  brands: brandsReducer,
  conditions: conditionsReducer,
  sizes: sizesReducer,
  validation: validationReducer,
  members: membersReducer,
  transactions: transactionsReducer,
  reports: reportsReducer,
  items: itemsReducer,
  cities: citiesReducer,
  wallets: walletsReducer,
  talents: talentsReducer,
  subcommunities: subcommunitiesReducer,
  knowledgeLibrary: knowledgeLibraryReducer,
  connect: connectReducer,
  shareittLovers: shareittLoversReducer,
};

export const rootSaga = [
  authSaga,
  communitySaga,
  rulesSaga,
  featuresSaga,
  categoriesSaga,
  brandsSaga,
  conditionsSaga,
  sizesSaga,
  validationSaga,
  membersSaga,
  transactionsSaga,
  reportsSaga,
  itemsSaga,
  walletsSaga,
  citiesSaga,
  talentsSaga,
  subcommunitiesSaga,
  knowledgeLibrarySaga,
  connectSaga,
  shareittLoversSaga,
];
