import React from 'react';
import Button, { buttonProps } from '../../../../components/Button';

const CreateTopicModal = ({ onCreateTopic, onCancel, value, onChangeText }) => {
  return (
    <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center">
      <div className="bg-white w-[30%] rounded-2xl shadow-lg flex flex-col items-center py-8">
        <span className="text-xl font-medium text-dark1">Create topic</span>

        <div className="w-full flex flex-col mt-11 px-4">
          <label className="text-lg text-dark1">Topic name</label>
          <input className="h-[50px] w-full border-1 border-gray10 bg-gray11 rounded pl-2 text-lg text-dark1"
            value={value}
            onChange={onChangeText}
          />
        </div>

        <div className="flex flex-row items-center justify-end gap-2 mt-8 w-full px-4">
          <Button
            theme={buttonProps.themes.gray}
            color={buttonProps.colors.dark}
            weight={buttonProps.weights.bold}
            marginTop={false}
            size={40}
            style={{ width: '100px' }}
            onClick={onCancel}
          >
            Cancel
          </Button>

          <Button
            theme={buttonProps.themes.secondary}
            color={buttonProps.colors.light}
            weight={buttonProps.weights.bold}
            marginTop={false}
            size={40}
            style={{ width: '100px' }}
            onClick={onCreateTopic}
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CreateTopicModal;
