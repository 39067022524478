import { createAction } from 'redux-actions';
import * as AT from './types';

export const getPendingPosts = createAction(AT.GET_PENDING_POSTS);
export const getPendingPostsSuccess = createAction(AT.GET_PENDING_POSTS_SUCCESS);
export const getApprovedPosts = createAction(AT.GET_APPROVED_POSTS);
export const getApprovedPostsSuccess = createAction(AT.GET_APPROVED_POSTS_SUCCESS);
export const approvePost = createAction(AT.APPROVE_POST);
export const approvePostSuccess = createAction(AT.APPROVE_POST_SUCCESS);
export const rejectPost = createAction(AT.REJECT_POST);
export const rejectPostSuccess = createAction(AT.REJECT_POST_SUCCESS);
export const createTopic = createAction(AT.CREATE_TOPIC);
export const createTopicSuccess = createAction(AT.CREATE_TOPIC_SUCCESS);
export const toggleHidePost = createAction(AT.TOGGLE_HIDE_POST);
export const toggleHidePostSuccess = createAction(AT.TOGGLE_HIDE_POST_SUCCESS);
export const getTopics = createAction(AT.GET_TOPICS);
export const getTopicsSuccess = createAction(AT.GET_TOPICS_SUCCESS);
