import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { findIndex, propEq } from 'ramda';
import menuItems, { menuItemsShareittEdu } from '../../../constants/menu';
import MenuItem from '../MenuItem';
import styles from './Menu.module.scss';
import { getAppName } from '../../../store/auth/selectors';

const Menu = memo(() => {
  // TODO: remove hardcode locale
  // const translate = useTranslate();
  const open = useSelector(state => state.admin.ui.sidebarOpen);
  const activeItemPath = useSelector(state => state.router.location.pathname);
  const appName = useSelector(getAppName);

  const formattedMenuItems = {
    shareitt: menuItems,
    floweavers: menuItemsShareittEdu,
  }[appName ?? 'shareitt'];

  return (
    <ul className={styles.nav}>
      {formattedMenuItems.map(itemProps => {
        // TODO: remove that workaround with selector?
        const isActiveItem = itemProps.childrenLinks
          ? findIndex(propEq('path', activeItemPath))(itemProps.childrenLinks) !== -1
          : itemProps.path === activeItemPath;

        return (
          <MenuItem
            key={itemProps.index}
            sidebarIsOpen={open}
            isActiveItem={isActiveItem}
            {...itemProps}
          />
        );
      })}
    </ul>
  );
});

export default withRouter(Menu);
