const menuItems = [
  {
    index: 'dashboard',
    header: 'pages.dashboard.title.menu',
    path: '/',
  },
  {
    index: 'communityInfo',
    header: 'pages.communityInfo.title.menu',
    path: '/communityInfo',
  },
  {
    index: 'subcommunities',
    header: 'pages.subcommunities.title.menu',
    path: '/spaces',
  },
  {
    index: 'groupInfo',
    header: 'pages.groupInfo.title.menu',
    path: '/groupInfo',
  },
  {
    index: 'connect',
    header: 'pages.connect.title.menu',
    path: '/connect',
  },
  {
    index: 'shareittLovers',
    header: 'pages.shareitt_lovers.title.menu',
    path: '/shareitt-lovers',
  },
  // {
  //   index: 'rules',
  //   header: 'pages.rules.title.menu',
  //   path: '/rules',
  // },
  // {
  //   index: 'features',
  //   header: 'pages.features.title.menu',
  //   path: '/features',
  // },
  {
    index: 'categories',
    header: 'pages.categories.title.menu',
    path: '/categories',
  },
  {
    index: 'brands',
    header: 'pages.brands.title.menu',
    path: '/brands',
  },
  {
    index: 'members',
    header: 'pages.members.title.menu',
    path: '/members',
  },
  {
    index: 'items',
    header: 'pages.items.title.menu',
    path: '/items',
  },
  {
    index: 'talents',
    header: 'pages.talents.title.menu',
    path: '/talents',
  },
  // {
  //   index: 'events',
  //   header: 'pages.events.title.menu',
  //   path: '/events',
  // },
  {
    index: 'kwonledgeLibrary',
    header: 'pages.knowledgeLibrary.title.menu',
    path: '/knowledge-library',
  },
  {
    index: 'transactions',
    header: 'pages.transactions.title.menu',
    path: '/transactions',
  },
  {
    index: 'reports',
    header: 'pages.reports.title.menu',
    path: '/reports',
  },
];

export const menuItemsShareittEdu = [
  {
    index: 'dashboard',
    header: 'pages.dashboard.title.menu',
    path: '/',
  },
  {
    index: 'communityInfo',
    header: 'pages.communityInfo.title.menu',
    path: '/communityInfo',
  },
  {
    index: 'subcommunities',
    header: 'pages.subcommunities.title.menu',
    path: '/spaces',
  },
  {
    index: 'groupInfo',
    header: 'pages.groupInfo.title.menu',
    path: '/groupInfo',
  },
  {
    index: 'categories',
    header: 'pages.categories.title.menu',
    path: '/categories',
  },
  {
    index: 'members',
    header: 'pages.members.title.menu',
    path: '/members',
  },
  {
    index: 'items',
    header: 'pages.items.title.menu',
    path: '/items',
  },
  {
    index: 'talents',
    header: 'pages.talents.title.menu',
    path: '/talents',
  },
  {
    index: 'kwonledgeLibrary',
    header: 'pages.knowledgeLibrary.title.menu',
    path: '/knowledge-library',
  },
  {
    index: 'transactions',
    header: 'pages.transactions.title.menu',
    path: '/transactions',
  },
  {
    index: 'reports',
    header: 'pages.reports.title.menu',
    path: '/reports',
  },
];

export default menuItems;
