import React from 'react';
import { Route } from 'react-router-dom';

import CommunityInfo from '../pages/CommunityInfo';
import GroupInfo from '../pages/GroupInfo';
import Categories from '../pages/Categories';
import Brands from '../pages/Brands';
import Members from '../pages/Members';
import MemberProfile from '../pages/MemberProfile';
import Transactions from '../pages/Transactions';
import Reports from '../pages/Reports';
import Items from '../pages/Items';
import Talents from '../pages/Talents';
import KnowledgeLibrary from '../pages/KnowledgeLibrary/PendingPage';
import Connect from '../pages/Connect';
import ShareittLovers from '../pages/ShareittLovers';
import Subcommunities from '../pages/Subcommunities/ListSubCommunities';
import CreateSubCommunity from '../pages/Subcommunities/CreateSubCommunity';

const Routes = [
  <Route path="/communityInfo" component={CommunityInfo} />,
  <Route path="/spaces" component={Subcommunities} />,
  <Route path="/create-space" component={CreateSubCommunity} />,
  <Route path="/edit-space/:spaceId" component={CreateSubCommunity} />,
  <Route path="/groupInfo" component={GroupInfo} />,
  <Route path="/categories" component={Categories} />,
  <Route path="/brands" component={Brands} />,
  <Route path="/members" component={Members} exact />,
  <Route path="/members/:memberId" component={MemberProfile} />,
  <Route path="/transactions" component={Transactions} />,
  <Route path="/knowledge-library" component={KnowledgeLibrary} />,
  <Route path="/reports" component={Reports} />,
  <Route path="/items" component={Items} exact />,
  <Route path="/talents" component={Talents} exact />,
  <Route path="/connect" component={Connect} exact />,
  <Route path="/shareitt-lovers" component={ShareittLovers} exact />,
  // <Route path="/wallets" component={Wallets} exact />,
  // <Route path="/cities" component={Cities} />,
];

export const ShareittEduRoutes = [
  <Route path="/communityInfo" component={CommunityInfo} />,
  <Route path="/spaces" component={Subcommunities} />,
  <Route path="/create-space" component={CreateSubCommunity} />,
  <Route path="/edit-space/:spaceId" component={CreateSubCommunity} />,
  <Route path="/groupInfo" component={GroupInfo} />,
  <Route path="/categories" component={Categories} />,
  <Route path="/members" component={Members} exact />,
  <Route path="/members/:memberId" component={MemberProfile} />,
  <Route path="/transactions" component={Transactions} />,
  <Route path="/knowledge-library" component={KnowledgeLibrary} />,
  <Route path="/reports" component={Reports} />,
  <Route path="/items" component={Items} exact />,
  <Route path="/talents" component={Talents} exact />,
];

export default Routes;
