import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';
import { connect, useSelector } from 'react-redux';
import { useTranslate } from 'react-admin';
import * as categoriesSelectors from '../../store/categories/selectors';
import * as categoriesActions from '../../store/categories/actions';
import ModalService from '../../modals/ModalService';
import { modalIds } from '../../modals/const';
import PageContent from '../../components/PageContent';
import Widget from '../../components/Widget';
import Button, { buttonProps } from '../../components/Button';
import GroupSelect from '../../components/GroupSelect';
import SortableGrid from './SortableGrid/SortableGrid';
import styles from './Categories.module.scss';
import { getAppName } from '../../store/auth/selectors';

const Categories = ({ parentCategories, setGroupId, reorderCategory, setIsCategoryActive }) => {
  const translate = useTranslate();

  const selectedAppName = useSelector(getAppName);

  const onGroupSelectChange = ({ value: groupId }) => {
    setGroupId(groupId);
  };

  const openCategoriesModal = () => {
    ModalService.open(modalIds.CATEGORIES_MODAL);
  };

  const openModalWithOptions = options => {
    ModalService.open(modalIds.CATEGORIES_MODAL, options);
  };

  const shouldShowSubcategories = {
    shareitt: true,
    floweavers: false,
  }[selectedAppName ?? 'shareitt'];

  console.log(shouldShowSubcategories, selectedAppName);

  return (
    <PageContent title={translate('pages.categories.title.page')}>
      <Widget>
        <div className={styles.categoriesNavigation}>
          <GroupSelect
            onChange={onGroupSelectChange}
            label={translate('pages.categories.labels.selectGroup')}
            className={cls('mb-5', 'flex-grow-1')}
          />
          <Button onClick={openCategoriesModal} theme={buttonProps.themes.link}>
            {translate('pages.categories.buttons.create')}
          </Button>
        </div>

        {shouldShowSubcategories && (
          <SortableGrid
            items={parentCategories}
            setIsCategoryActive={setIsCategoryActive}
            reorderCategory={reorderCategory}
            openModal={openModalWithOptions}
          />
        )}
      </Widget>
    </PageContent>
  );
};

Categories.propTypes = {
  parentCategories: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setGroupId: PropTypes.func.isRequired,
  reorderCategory: PropTypes.func.isRequired,
  setIsCategoryActive: PropTypes.func.isRequired,
};

export default connect(
  state => {
    return {
      parentCategories: categoriesSelectors.getParentCategories(state),
    };
  },
  {
    setGroupId: categoriesActions.setGroupId,
    reorderCategory: categoriesActions.reorderCategory,
    setIsCategoryActive: categoriesActions.setIsCategoryActive,
  },
)(Categories);
