import React, { useState } from 'react';
import { useTranslate } from 'react-admin';
import { CriteriaItem } from '../components/CriteriaItem';
import Button, { buttonProps } from '../../../components/Button';
import { connect } from 'react-redux';
import * as shareittLoversActions from '../../../store/shareittLovers/actions';
import * as communityActions from '../../../store/community/actions';
import { getCurrentCommunity } from '../../../store/community/selectors';

const CriteriaPage = ({ updateCommunity, currentCommunity, getCommunities }) => {
  const translate = useTranslate();

  const criteriaKeys = [
    'minimunNumberOfExchanges',
    'minimunDifferentPeopleExchanged',
    'minimunVerifiedMemberEndorsements',
    'minimumCreatedAt',
  ];

  const initialValues = criteriaKeys.reduce((acc, key) => {
    const value = currentCommunity?.communityLoversConfig?.criteria?.[key]?.value || '';
    acc[key] = key === 'minimumCreatedAt' && value ? new Date(value).toISOString().split('T')[0] : value;
    return acc;
  }, {});

  const initialActiveStates = criteriaKeys.reduce((acc, key) => {
    acc[key] = !!currentCommunity?.communityLoversConfig?.criteria?.[key]?.active;
    return acc;
  }, {});

  const [criteria, setCriteria] = useState(initialValues);
  const [activeStates, setActiveStates] = useState(initialActiveStates);

  const handleInputChange = (key, value) => {
    setCriteria((prev) => ({
      ...prev,
      [key]: key === 'minimumCreatedAt' ? value : value,
    }));
  };

  const handleDeleteCriteria = (key) => {
    const updatedCriteria = criteriaKeys.reduce((acc, k) => {
      acc[k] = {
        value: criteria[k],
        active: !!criteria[k],
      };
      return acc;
    }, {});

    setActiveStates((prev) => ({ ...prev, [key]: false }));

    updateCommunity({
      communityLoversConfig: {
        criteria: updatedCriteria,
      },
    });
    getCommunities();
  };

  const handleActivateCriteria = (key) => {
    const updatedCriteria = criteriaKeys.reduce((acc, k) => {
      acc[k] = {
        value: criteria[k],
        active: !!criteria[k],
      };
      return acc;
    }, {});

    setActiveStates((prev) => ({ ...prev, [key]: true }));

    updateCommunity({
      communityLoversConfig: {
        criteria: updatedCriteria,
      },
    });

    getCommunities();
  };

  const handleSubmit = () => {
    const updatedCriteria = criteriaKeys.reduce((acc, key) => {
      acc[key] = { value: criteria[key], active: !!criteria[key] };
      return acc;
    }, {});

    updateCommunity({
      communityLoversConfig: {
        criteria: updatedCriteria,
      },
    });

    getCommunities();
  };

  const criteriaConfig = [
    {
      key: 'minimunNumberOfExchanges',
      title: translate('pages.shareitt_lovers.criteria_page.minimun_number_of_exchanges'),
    },
    {
      key: 'minimunDifferentPeopleExchanged',
      title: translate('pages.shareitt_lovers.criteria_page.minimun_different_people_exchanged'),
    },
    {
      key: 'minimunVerifiedMemberEndorsements',
      title: translate('pages.shareitt_lovers.criteria_page.minimun_verified_member_endorsements'),
    },
    {
      key: 'minimumCreatedAt',
      title: translate('pages.shareitt_lovers.criteria_page.minimum_created_at'),
    },
  ];

  return (
    <div className="flex flex-col w-full h-full items-center justify-center">
      <div className="bg-gray3 py-6 px-5 max-w-[50%] border-1 border-gray2">
        <span className="text-lg font-medium text-dark5">
          {translate('pages.shareitt_lovers.criteria_page.define_the_criteria_description')}
        </span>

        <div className="mt-14">
          {criteriaConfig.map(({ key, title }) => (
            <CriteriaItem
              key={key}
              title={title}
              inputValue={criteria[key]}
              isActive={activeStates[key]}
              onInputChange={(e) => handleInputChange(key, e.target.value)}
              onDesactivateCriteria={() => handleDeleteCriteria(key)}
              onActivateCriteria={() => handleActivateCriteria(key)}
            />
          ))}
        </div>
      </div>

      <div className="flex flex-row items-center justify-end gap-2 mt-8 w-[50%]">
        <Button
          theme={buttonProps.themes.secondary}
          color={buttonProps.colors.light}
          weight={buttonProps.weights.bold}
          size={40}
          style={{ width: '100px' }}
          onClick={handleSubmit}
        >
          {translate('general.button.save')}
        </Button>

        <Button
          theme={buttonProps.themes.gray}
          color={buttonProps.colors.dark}
          weight={buttonProps.weights.bold}
          size={40}
          style={{ width: '100px' }}
        >
          {translate('general.button.cancel')}
        </Button>
      </div>
    </div>
  );
};

export default connect(
  (state) => ({
    currentCommunity: getCurrentCommunity(state),
  }),
  {
    updateCommunity: shareittLoversActions.updateCommunity,
    getCommunities: communityActions.getCommunities,
  },
)(CriteriaPage);
