export const getPosts = (state) => {
  return state.connect.posts;
};

export const getGroups = (state) => {
  return state.connect.groups;
}

export const getIsLoading = (state) => {
  return state.connect.isLoading;
}
