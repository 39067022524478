import React, { useEffect, useState } from 'react';
import SearchInput from '../../../components/Search';
import TopicItem from './components/TopicItem';
import CreateTopicModal from '../components/CreateTopicModal';
import { connect } from 'react-redux';
import * as knowledgeLibraryActions from '../../../store/knowledgeLibrary/actions';
import * as knowledgeLibrarySelectors from '../../../store/knowledgeLibrary/selectors';

const TopicPage = ({ createTopic, getTopics, topics }) => {
  const [showCreateTopicModal, setShowCreateTopicModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [topicValue, setTopicValue] = useState('');

  const handleCreateTopic = async () => {
    await createTopic({ topic: topicValue });
    setShowCreateTopicModal(false);
  };

  const handleCreateTopicModal = () => {
    setShowCreateTopicModal((prev) => !prev);
  };

  const handleSearchChange = (value) => {
    setSearchTerm(value);
    getTopics(value);
  };

  useEffect(() => {
    getTopics();
  }, []);

  return (
    <div>
      <div className="flex flex-row items-center justify-between">
        <SearchInput
          placeholder={'Search by topic'}
          className={'w-96 mt-2'}
          onChange={handleSearchChange}
          value={searchTerm}
        />

        <div className="bg-white w-full flex items-center justify-end px-9 py-5">
          <button className="text-lg font-medium text-bluePrimary" onClick={handleCreateTopicModal}>
            Create
          </button>
        </div>
      </div>

      <div className="bg-gray9 w-full p-8 gap-4 flex flex-col">
        {topics?.length > 0 &&
          topics?.map((topic) => <TopicItem key={topic.id} topic={topic.topic} />)}
      </div>

      {showCreateTopicModal && (
        <CreateTopicModal
          onCreateTopic={handleCreateTopic}
          onCancel={handleCreateTopicModal}
          onChangeText={(e) => setTopicValue(e.target.value)}
          value={topicValue}
        />
      )}
    </div>
  );
};

export default connect(
  (state) => {
    return {
      topics: knowledgeLibrarySelectors.getTopics(state),

      //   posts: knowledgeLibrarySelectors.getPosts(state),
      // isLoading: knowledgeLibrarySelectors.getIsLoading(state),
    };
  },
  {
    createTopic: knowledgeLibraryActions.createTopic,
    getTopics: knowledgeLibraryActions.getTopics,
  },
)(TopicPage);
