import { handleActions } from 'redux-actions';
import { CLEAR_STATE } from 'ra-core';
import { deepMerge } from '../../utils/store';
import * as AT from './types';

const initialState = {
  appName: null,
};

const reducer = handleActions(
  {
    [AT.SET_SELECTED_APP_NAME]: deepMerge(({ payload: appName }) => {
      return { appName };
    }),
    [CLEAR_STATE]: () => {
      return initialState;
    },
  },
  initialState,
);

export default reducer;
