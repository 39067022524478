import React from 'react';
import AlertTriangle from '../../assets/icons/alert-triangle.svg';

const Popup = ({ closeModal, title, onConfirm }) => {
  return (
    <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg shadow-lg max-w-lg w-full p-6">
        <div className="flex justify-center text-yellow-500 mb-4">
          <img src={AlertTriangle} />
        </div>

        <p className="text-center text-[16px] font-semibold text-black mb-6">{title}</p>

        <div className="flex justify-between gap-2">
          <button
            onClick={closeModal}
            className="border-1 border-red px-4 py-2 rounded-[20px] w-full text-[16px] font-semibold text-red"
          >
            Cancel
          </button>
          <button
            onClick={() => {
              onConfirm();
            }}
            className="bg-bluePrimary text-white text-[16px] font-semibold px-4 py-2 rounded-[20px] w-full"
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
};

export default Popup;
