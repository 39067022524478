import { all, takeEvery, call, put, select } from 'redux-saga/effects';
import { showNotification } from 'react-admin';
import api from '../../api/knowledgeLibrary';
import { getSelectedCommunityId } from '../community/selectors';
import * as AT from './types';
import * as actions from './actions';

function* onGetPendingPosts({ payload }) {
  try {
    const selectedCommunityId = yield select(getSelectedCommunityId);
    const posts = yield call(api.getPosts, selectedCommunityId, true, payload.searchTerm, payload.topics);
    yield put(actions.getPendingPostsSuccess(posts));
  } catch (error) {
    yield put(showNotification('Error fetching pending posts', 'error'));
  }
}

function* onGetApprovedPosts({ payload }) {
  try {
    const selectedCommunityId = yield select(getSelectedCommunityId);
    const posts = yield call(api.getPosts, selectedCommunityId, false, payload.searchTerm, payload.topics);
    yield put(actions.getApprovedPostsSuccess(posts));
  } catch (error) {
    yield put(showNotification('Error fetching approved posts', 'error'));
  }
}

function* onApprovePost({ payload }) {
  try {
    yield call(api.approvePost, payload);
    yield put(actions.approvePostSuccess(payload));
    yield put(showNotification('Post approved', 'info'));
    yield put(actions.getPendingPosts());
    yield put(actions.getApprovedPosts());
  } catch (error) {
    yield put(showNotification('Error approving post', 'error'));
  }
}

function* onRejectPost({ payload }) {
  try {
    yield call(api.rejectPost, payload);
    yield put(actions.rejectPostSuccess());
    yield put(showNotification('Post rejected', 'info'));
    yield put(actions.getPendingPosts());
    yield put(actions.getApprovedPosts());
  } catch (error) {
    yield put(showNotification('Error rejecting post', 'error'));
  }
}

function* onCreateTopic({ payload }) {
  try {
    const selectedCommunityId = yield select(getSelectedCommunityId);

    const data = {
      ...payload,
      community_id: selectedCommunityId,
    }

    yield call(api.createTopic, data);
    yield put(actions.createTopicSuccess());
    yield put(showNotification('Topic created', 'info'));
    yield put(actions.getTopics());
  } catch (error) {
    yield put(showNotification('Error creating topic', 'error'));
  }
}

function* onGetTopics({ payload: searchTerm }) {
  try {
    const selectedCommunityId = yield select(getSelectedCommunityId);
    const topics = yield call(api.getTopics, selectedCommunityId, searchTerm);
    yield put(actions.getTopicsSuccess(topics));
  } catch (error) {
    yield put(showNotification('Error fetching topics', 'error'));
  }
}
function* onToggleHidePost({ payload }) {
  try {
    yield call(api.toggleHidePost, payload);
    yield put(actions.toggleHidePostSuccess());
    yield put(showNotification('Post hidden', 'info'));
    yield put(actions.getPendingPosts());
    yield put(actions.getApprovedPosts());
  } catch (error) {
    yield put(showNotification('Error hiding post', 'error'));
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(AT.GET_PENDING_POSTS, onGetPendingPosts),
    takeEvery(AT.GET_APPROVED_POSTS, onGetApprovedPosts),
    takeEvery(AT.APPROVE_POST, onApprovePost),
    takeEvery(AT.REJECT_POST, onRejectPost),
    takeEvery(AT.CREATE_TOPIC, onCreateTopic),
    takeEvery(AT.TOGGLE_HIDE_POST, onToggleHidePost),
    takeEvery(AT.GET_TOPICS, onGetTopics),
  ]);
}
