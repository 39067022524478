import { handleActions } from 'redux-actions';
import { CLEAR_STATE } from 'react-admin';
import * as AT from './types';

const initialState = {
    pendingPosts: [],
    approvedPosts: [],
    topics: [],
};

const reducer = handleActions({
    [AT.GET_PENDING_POSTS_SUCCESS]: (state, action) => ({
        ...state,
        pendingPosts: action.payload,
    }),
    [AT.GET_APPROVED_POSTS_SUCCESS]: (state, action) => ({
        ...state,
        approvedPosts: action.payload,
    }),
    [AT.GET_TOPICS_SUCCESS]: (state, action) => ({
        ...state,
        topics: action.payload,
    }),
    [AT.APPROVE_POST_SUCCESS]: (state, action) => ({
        ...state,
        pendingPosts: state.pendingPosts.filter(post => post.id !== action.payload),
    }),
}, initialState);

export default reducer;
