import React from 'react';

const TopicItem = ({ topic }) => {
  return (
    <div className="w-full h-[56px] border-1 border-gray2 bg-white flex flex-row items-center justify-between px-4">
      <span className="text-lg text-black">{topic}</span>
    </div>
  );
};

export default TopicItem;
