import React from 'react';
import format from 'date-fns/format';

const PostItemDetails = ({ post, onClose }) => {
  return (
    <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center">
      <div className="bg-white max-w-[40%] rounded-2xl shadow-lg flex flex-col items-center p-8 relative">
        <button
          className="absolute top-4 right-4 text-gray-500 text-2xl hover:text-gray-700"
          onClick={onClose}
        >
          &times;
        </button>
        <img src={post.attachment} alt="post" className="w-24 h-24 rounded-full" />
        <span className="text-2xl font-bold text-dark1">{post.title}</span>
        <span className="text-xl font-medium text-dark1 pt-2 text-center">{post.content}</span>
        <div className="flex flex-row items-center justify-between mt-8 w-full gap-4">
          <div className="flex flex-row items-center gap-2 ">
            <img src={post.author.profile_image} alt="author" className="w-6 h-6 rounded-full" />
            <span className="text-xl font-medium text-dark1">{post.author.name}</span>
          </div>
          <span className="text-xl font-medium text-dark1">
            {format(new Date(post.created_at), 'dd MMM yyyy HH:mm')}
          </span>
        </div>
      </div>
    </div>
  );
};

export default PostItemDetails;
