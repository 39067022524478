export const IMAGES_URL = {
  LOGO: '/images/shareitt_logo.png',
  DEFAULT_IMAGE: '/images/share_itt_default.jpeg',
  ICON_CONFIGURE: '/images/configure.svg',
  ICON_ABOUT: '/images/about.svg',
  ICON_GENERAL: '/images/general.svg',
  LOGIN: '/images/login.svg',
  CONFIRMATION: '/images/confirmation.svg',
  ICON_FILTER: '/images/filter.svg',
  ICON_EARTH: '/images/earth.svg',
  ICON_CONNECT: '/images/connect.svg',
  ICON_TRASH: '/images/trash.svg',
  ICON_SHAREITT_LOVERS: '/images/shareitt-lovers.svg',
};
