import { handleActions } from 'redux-actions';
import { CLEAR_STATE } from 'react-admin';
import * as AT from './types';
import { deepMerge } from '../../utils/store';

const initialState = {
  posts: [],
  groups: [],
  isLoading: false,
};

const reducer = handleActions(
  {
    [AT.SET_IS_LOADING]: deepMerge(({ payload }) => ({
      isLoading: payload,
    })),

    [AT.GET_POSTS_SUCCESS]: (state, action) => ({
      ...state,
      posts: action.payload,
    }),

    [AT.GET_GROUPS_SUCCESS]: (state, action) => ({
      ...state,
      groups: action.payload,
    }),

    [CLEAR_STATE]: () => initialState,
  },
  initialState,
);

export default reducer;
