import React from 'react';
import ReactDOM from 'react-dom';

const Tooltip = ({ visible, position, children }) => {
  if (!visible) return null;

  return ReactDOM.createPortal(
    <div
      className="absolute bg-white border border-gray2 shadow-md rounded-2xl z-50 py-3 flex flex-col items-center justify-center"
      style={{
        top: position.top,
        left: position.left,
        position: 'absolute',
      }}
    >
      {children}
    </div>,
    document.body,
  );
};

export default Tooltip;
