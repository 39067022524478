import { createAction } from 'redux-actions';
import * as AT from './types';

export const getUsersLovers = createAction(AT.GET_USERS_LOVERS);
export const getUsersLoversSuccess = createAction(AT.GET_USERS_LOVERS_SUCCESS);

export const addUserLover = createAction(AT.ADD_USER_LOVER);
export const addUserLoverSuccess = createAction(AT.ADD_USER_LOVER_SUCCESS);

export const removeUserLover = createAction(AT.REMOVE_USER_LOVER);
export const removeUserLoverSuccess = createAction(AT.REMOVE_USER_LOVER_SUCCESS);

export const setIsLoading = createAction(AT.SET_IS_LOADING);

export const getUsersAvailable = createAction(AT.GET_USERS_AVAILABLE);
export const getUsersAvailableSuccess = createAction(AT.GET_USERS_AVAILABLE_SUCCESS);

export const updateCommunity = createAction(AT.UPDATE_COMMUNITY);
export const updateCommunitySuccess = createAction(AT.UPDATE_COMMUNITY_SUCCESS);
